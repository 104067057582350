import React from "react"
import Layout from "../components/Layout/Layout"
import Paper from "../components/global/Paper"
import { Helmet } from "react-helmet"
import styles from "./about.module.css"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default function About() {
  const data = useStaticQuery(graphql`
    query {
      jamieImage: file(relativePath: { eq: "images/about/Jamie_Hi_Res.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lundubhImage: file(
        relativePath: { eq: "images/about/Lundubh_Bio.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kmdImage: file(
        relativePath: { eq: "images/about/kmd_bio.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout centered={false} style={{ backgroundColor: "#FEFAB090" }}>
      <Helmet>
        <title>About | Circa Wallcoverings</title>
        <meta
          name="Description"
          content="Bold/stylish/luxe with a wink best describes Circa Wallcovering - a collection created and curated by Jamie Adler."
        />
      </Helmet>
      <div className={styles.paperContainer}>
        <Paper
          width={"80%"}
          margin={"0 auto"}
          marginBottom={"75px"}
          minHeight={"60vh"}
          height={"auto"}
          display={"flex"}
          alignItems={"center"}
          padding={"25px"}
        >
          <div className={styles.flexContainer}>
            <div className={styles.textContainer}>
              <p>
                Bold/<b>stylish</b>/luxe with a wink best describes Circa
                Wallcovering - a collection created and curated by Jamie Adler.
              </p>
              <br />
              <br />
              <p>
                Having grown up immersed in a California creative culture, Jamie
                and believes that if the spaces are the sets we live our lives
                in, walls are our most important backdrops, and they should say
                something.
              </p>
              <br />
              <br />
              <p>
                We continuously add metallic, colorful and large-scaled designs
                to the collection and in keeping with our family philosophy of
                making everything locally and to order, all Circa Wallcoverings
                are printed in Los Angeles on and sold by the yard and in custom
                colorations upon request. Our wallcoverings are found in hotels,
                specialty restaurants, and retail boutiques and in the most
                private spaces throughout the world.
              </p>
              <br />
              <br />
              <p>
                <i>
                  All materials are of the highest quality while the printing
                  technique utilizes a zero VOC UV process with non-toxic inks.
                </i>
              </p>
            </div>
            <Img
              fluid={data.jamieImage.childImageSharp.fluid}
              className={styles.imgStyle}
            />
          </div>
        </Paper>
        <Paper
          width={"80%"}
          margin={"0 auto 50px"}
          marginBottom={"75px"}
          minHeight={"60vh"}
          height={"auto"}
          display={"flex"}
          alignItems={"center"}
          padding={"25px"}
        >
          <div className={styles.flexContainer}>
            <div className={styles.textContainer}>
              <p>
                Londubh Studio (pronounced Lún-duv/rhymes with love) was founded
                in 2011 by Lisa Donohoe and Brynn Gelbard, two women dedicated
                to living life on the edge in full color and shimmer whose art
                and surface designs are an unapologetic love letter to
                maximalism and glam. Much of their body of work is built to last
                in prominent high-end (as well as unexpected) spaces such as
                NASA's Jet Propulsion Lab, The Jean Georges Restaurant at the
                Beverly Hills Waldorf Astoria, and the San Francisco Asian Art
                Museum, to name a few. Their backdrops most recently featured in
                the 2020 Golden Globes elevator set for InStyle Magazine as
                designed by Nicole Hollis, with whom the pair have been quietly
                working away on a secret San Francisco project sure to shake up
                the world of design.
              </p>
              <br />
              <br />
              <p>
                Donohoe's and Gelbard's foray into the world of wallcovering
                makes their art and designs available for the first time without
                the need for scaffolding and the months it takes to develop and
                execute their intricate rock star on-site and hand-adorned
                creations for which they are known.
              </p>
              <br />
              <br />
              <p>
                "We're not aiming for the perpetual minimalist," say Lisa and
                Brynn collectively. "We dedicate this collection to those
                emerging from this monumental time with a renewed fervor for
                seizing the moment and making a statement." All considering, who
                better to collaborate with for their debut than Circa.
              </p>
              <br />
              <br />
              <p>
                As for inspiration? Says Brynn, "We regularly disappear into
                nature to get quiet, connect with our hearts, and call in
                designs. Our best visions never come when we sit down with a cup
                of coffee and a pencil at nine in the morning. They come when
                Lisa's subconsciously doodling when we're on a call, or when
                we're wandering around somewhere outside with our dogs during
                Magic Hour. They're birthed from a spirit we share of wanting to
                celebrate life, our mutual abandonment of societal expectations
                if they don't help us manifest our dreams, and our shared
                conviction that more is indeed more when it comes to the
                decorative canvas and its ability to fuel us to live our best
                lives."
              </p>
            </div>
            <Img
              fluid={data.lundubhImage.childImageSharp.fluid}
              className={styles.imgStyle}
            />
          </div>
        </Paper>
        <Paper
          width={"80%"}
          margin={"0 auto 50px"}
          marginBottom={"75px"}
          minHeight={"60vh"}
          height={"auto"}
          display={"flex"}
          alignItems={"center"}
          padding={"25px"}
        >
          <div className={styles.flexContainer}>
            <div className={styles.textContainer}>
              <p>
                Kimberly McDonala's signature #ilikerocks represents a lifelong passion. A
                passion expressed in her namesake collection. In the 13 years since she opened
                Kimberly has expanded her reach from fine jewelry to now include clothing and
                accessories as well as eco-referenced home products which all revolve
                seamlessly around a deep appreciation of the melding of the rough and the
                fine in life as it is in nature.
              </p>
              <br />
              <br />
              <p>
                Kimberly McDonald (aka KMD) launched in 2007 in New York City, following her
                freelance career as a curator of private clients’ fine jewelry collections.
                Composing collections left a mark on her, sparking the creation of a brand
                where every one-of-a-kind piece carries a story and invites connection. A
                reflection of her connection to stones and natural materials, connection
                between the collector and a piece, between KMD and trusted suppliers,
                between the company and the planet. Connection is a hallmark of the brand
                and a love of all things natural is more than a foundation for her work as a
                designer; sustainability is integral to the brand's existence.
              </p>
              <br />
              <br />
              <p>
                Creation without destruction is just how we operate. We seek out the finest
                natural materials, reclaimed gold, and recycled diamonds to handcraft the
                collection in the United States with sustainability in mind. Kimberly's design
                aesthetic has always focused on letting the natural speak for itself, so these
                environmentally respectful practices evolved organically. “I'm proud that | have
                such strong relationships with every level of the process—from miners to
                craftsmen. Each piece is made with care and respect."
              </p>
              <br />
              <br />
              <p>
                The Kimberly McDonald collection has received overwhelming support and
                recognition from editors, stylists and celebrities alike. To count Former First Lady
                Michelle Obama, Cindy Crawford, Victor Cruz, Carrie Underwood, Vanessa
                Williams, Amy Adams, Cameron Diaz, Steph Curry and more as loyalists is a
                privilege. The Smithsonian National Museum of American History houses
                Kimberly's one-of-a-kind diamond-embellished ring that was part of the Jason
                Wu dress that Former First Lady Michelle Obama wore to the Second Inaugural
                Ball.
              </p>
              <br />
              <br />
              <p>
                “| hope that when people experience one of my pieces, not only are they
                transfixed by the beautiful stone and craftsmanship; | hope that they are also
                inspired to be a little more mindful and a little more protective of the planet that
                provides these magnificent little treasures that we wear as talismanic symbols of
                beauty and transducers of energy.
              </p>
            </div>
            <Img
              fluid={data.kmdImage.childImageSharp.fluid}
              className={styles.imgStyle}
            />
          </div>
        </Paper>
      </div>
    </Layout>
  )
}
